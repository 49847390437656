<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="关于我们" ></m-header>
    <div class="main">
        <p class="main-text">
            <span>Hi</span><span>，我是Mooby，一个小小的收藏家，我最喜欢将全世界的潮流好物收入囊中，黑科技产品，护肤美妆产品，家居生活产品，还有宠物必备，通通不在话下，无关你的年纪，性别，喜好，在Mooby总会找到你想要的！Mooby Yoho，一个全新的私域电商平台，所有产品均经过三层严格筛选，保证您用的安心，用的舒心；我们承诺所有商品7天无忧退换，24-72小时极速达。同时Mooby Yoho将开启全民无门槛创业新时代，让省钱成为必然，赚钱变得简单。Mooby Yoho，好商品有我就购了！</span>
        </p>
        <p class="main-text">
            <span>Mooby</span><span>属于自加拿大MC国际，MC国际以跨境电商仓储，全球物流配送为发展方向，努力打造全球化零售产业生态链为发展核心，把爱送达全世界。目前，MC国际公司的业务已经覆盖加拿大西海岸九大城市，其中包括温哥华市，列治文市，本拿比市，西温哥华市，北温哥华市，素里市，白石市，三角洲市，高贵林市，枫树岭市，兰里市。 Mooby的小伙伴已经在加拿大和中国杭州分别设立了服务办公室，中国区的小伙伴会集合最优秀的生产厂家，通过严格的质量筛选和技术数据支持，为我们提供可靠，先进的技术保障。加拿大的小伙伴将不断在加拿大其它省市开设更多的服务网点，更好的服务有需求的人群，为实现我们全球零售生态的重要目标，建设坚实的基础。Mooby也希望通过自己的努力，为加拿大解决更多的就业问题，创新发展问题。Mooby想尽快长大，成为您生活中不可或缺的伙伴，有Mooby，就有爱，Yoho无处不在。</span>
        </p>
        <p>
            <span>&nbsp;</span>
        </p>
        <p class="ta-l">
            <strong><span>公司地址/Company Adress：11121 Horseshoe Way Unit 133, Richmond, BC V7A 5G7</span></strong>
        </p>
        <p class="ta-l">
            <strong><span>&nbsp;</span></strong>
        </p>
        <p class="ta-c">
            <img width="98%" src="@/assets/spacer.png"/>
        </p>
        <p>
            <span>客服邮箱：</span>
        </p>
        <p>
            <em><span>customerservice@moobyyoho.com</span></em>
        </p>
        <p>
            <span>客服微信号：</span>
        </p>
        <p>
            <em><span>mooby_yoho</span></em>
        </p>
        <p class="ta-l">
            <span><span class="under-no">合作咨询邮箱：</span></span>
        </p>
        <p class="ta-l">
            <em><span><span class="under-no">cooperate@moobyyoho.com</span></span></em>
        </p>
        <p class="ta-l">
            <em><span class="col-span">(we are welcome company cooperate oppourtunity,please send your information to our cooperate email address.Thanks!)</span></em>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    .main-text {text-indent:32px}
    .ta-l{text-align: left;}
    .ta-c{text-align: center;}
    .under-no{color:windowtext;text-underline:none;}
    .col-span{color: windowtext; font-family: '微软雅黑', sans-serif;}
  }
}
</style>